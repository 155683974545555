import BaseModel from '../core/BaseModel'

export default class NotificationsModel extends BaseModel {

    constructor() {
        super({
            tableName: 'notification', label: "Quản Lý Thông Báo", display_key: 'id'
        }, {
            stt: { 
                name: "stt", 
                label: "STT", 
                class: 'center', 
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true,  
                display: false
            },
            receiver: { 
                name: "receiver", 
                label: "Người nhận", 
                width: 20,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.receiver = ''
                }
            },
            title: { 
                name: 'title', 
                type: "String", 
                label: 'Tiêu đề', 
                // class: 'center', 
                width: 20,
                filter: {type: 'input', match: 'contains'},
                //display: false
            },
            content: { 
                name: 'content', 
                type: "String", 
                label: 'Nội dung',
                width: 80,
                display: false
            },
            action_key : {
                name: 'action_key', 
                type: "String", 
                label: 'Hành động',
                display: false
            },
            is_push: {
                name: 'is_push', 
                type: 'Boolean', 
                label: 'Đã gửi',
                display: false
            },
            is_admin: {
                name: 'is_admin', 
                type: 'Boolean', 
                label: 'Hiển thị trên admin',
                display: false
            },
            type: { 
                name: 'type', 
                type: "String", 
                label: 'Type',  
                width: 20,
                display: false
            },
            created_at : { 
                name: 'created_at', 
                type: "Datetime", 
                width: 10,
                label: 'Ngày tạo',
                filter: { type: 'DateRange', match: 'contains'}
            },
            updated_at : { 
                name: 'updated_at', 
                type: "Datetime", 
                display: false,
                label: 'Ngày cập nhật',
                filter: { type: 'DateRange', match: 'contains'}
            },
            user_ref: { 
                refs: 'user {id fullname}',
                display: false
            },
            type_ref: { 
                refs: 'notification_type {id value}',
                display: false
            },



        })
    }

}