import BaseService from './BaseService'
import UserModel from '../models/UserModel'
import AdminModel from '../models/AdminModel'
import AccountsModel from '../models/AccountsModel'
import NotificationsModel from '../models/NotificationsModel'

import FileModel from "@/models/FileModel";
import RelationshipFileModel from "../models/RelationshipFileModel";
import GroupModel from '../models/GroupModel'
import GroupUserModel from '../models/GroupUserModel'
import SettingModel from '../models/SettingModel'
import PolicyModel from '../models/PolicyModel'
import ControllerModel from '../models/ControllerModel'

import DecksModel from '../models/DecksModel'
import HistoryPayment from '../models/HistoryPayment'
import PackagesModel from '../models/PackagesModel'
import StatisticalModels from '../models/StatisticalModels'
import OptionRepeatModel from '../models/OptionRepeatModel'

export default {
    services: {
      'setting': {
        'service': new BaseService(new SettingModel()),
        'model': new SettingModel()
      },

      'accounts': {
        'model': new AccountsModel(),
        'service': new BaseService(new AccountsModel())
      },
      'view_admin': {
        'model': new AdminModel(),
        'service': new BaseService(new AdminModel())
      },
      'group': {
        'model': new GroupModel(),
        'service': new BaseService(new GroupModel())
      },
      'group_user': {
        'model': new GroupUserModel(),
        'service': new BaseService(new GroupUserModel())
      },
      'users': {
        'model': new UserModel(),
        'service': new BaseService(new UserModel())
      },
      'file': {
        'model': new FileModel(),
        'service': new BaseService(new FileModel())
      },
      'relationship_file': {
        'model': new RelationshipFileModel(),
        'service': new BaseService(new RelationshipFileModel())
      },
      // 'notifications': {
      //     'service': new BaseService(new NotificationsModel()),
      //     'model': new NotificationsModel(),
      //     'rels': [],
      // },
      'notification': {
        'service': new BaseService(new NotificationsModel()),
        'model': new NotificationsModel()
      },
      'policy': {
        'service': new BaseService(new PolicyModel()),
        'model': new PolicyModel()
      },
      'controller': {
        'service': new BaseService(new ControllerModel()),
        'model': new ControllerModel()
      },
      
      'decks' : {
        'service': new BaseService(new DecksModel()),
        'model': new DecksModel()
      },
      'history_payment' : {
        'service': new BaseService(new HistoryPayment()),
        'model': new HistoryPayment()
      },
      'packages' : {
        'service': new BaseService(new PackagesModel()),
        'model': new PackagesModel()
      },
      'statistical': {
        'service': new BaseService(new StatisticalModels()),
        'model': new StatisticalModels()
      },
      'option_repeat': {
        'service': new BaseService(new OptionRepeatModel()),
        'model': new OptionRepeatModel()
      },
    },
    getModel(modelName) {
      return this.services[modelName].model
    },
    getList(modelName, params) {
      return this.services[modelName].service.getListQuery(params);
    },
    countData(modelName) {
      return this.services[modelName].service.countDataQuery();
    },
    updateData(modelName) {
        return this.services[modelName].service.updateDataQuery();
    },
    insertData(modelName) {
      return this.services[modelName].service.insertDataQuery();
    },
    deleteData(modelName) {
      return this.services[modelName].service.deleteDataQuery();
    },
    getByMyId(modelName,params) {
      return this.services[modelName].service.getByMyIdQuery(params);
    },
};
