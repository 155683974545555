import moment from "moment-timezone";
export default {
    getConstans() {
        return {
            DEFAULT_TIMEZONE: "Asia/Ho_Chi_Minh",
            DB_TIMEZONE: "UTC",
            FORMAT_DATE_TIME_CUSTOM: "HH:mm:ss DD/MM/YYYY",
            FORMAT_DATE_DISPLAY: "DD/MM/YYYY",
            FORMAT_DATE_SEVER: "YYYY-MM-DD",
            FORMAT_DATE_TIME_PRIMEVUE: "DD/MM/YYYY HH:mm:ss",
            CURRENT_TIMEZONE_CLIENT_UTC:moment().tz("UTC").format('YYYYMMDDHHmmss'),
            TYPE_GIFT_CARD:'card',
            IS_OTP_ACTIVED:2,
            MSG_ERROR : {
                INVALID_TOKEN_ERR: "INVALID_TOKEN_ERR",
                MISMATCH_PARAMS_ERR: "MISMATCH_PARAMS_ERR",
                INVALID_CURRENT_PASSWORD_ERR: "INVALID_CURRENT_PASSWORD_ERR",
                PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR: "PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR",// password mới không đucợ giống password cũ
                ACCOUNT_IS_NOT_EXISTED_ERR: "ACCOUNT_IS_NOT_EXISTED_ERR",
                ACCOUNT_IS_DEACTIVE_ERR: "ACCOUNT_IS_DEACTIVE_ERR",
            },
            MSG_TEXT:{
                TITLE_SUCCESS_DIALOG:'Thành công',
                TITLE_ERR_DIALOG:'Lỗi',
                UPDATE_SUCCESS_DIALOG:'Cập nhật thành công',
                INPUT_INVALID_MSG:'Vui lòng điền vào trường này',
                SELECT_INVALID_MSG:'Vui lòng chọn một mục trong danh sách',
                FILE_INVALID_MSG:'Vui lòng chọn một tệp',
                VIDEO_PENDING_UPLOAD_MSG:'File đang được upload vui lòng đợi...',
                RADIO_BUTTON_INVALID_MSG:'Vui lòng chọn loại giao dịch',
                IMAGE_INVALID_MSG:'Vui lòng chọn hình ảnh',
                UPLOAD_IMAGE_FAILURE:'Upload hình ảnh thất bại.Vui lòng cập nhật lại.',
                SELECT_ADDRESS_MSG:'Vui lòng chọn địa chỉ từ danh sách gợi ý!',
                PHONE_EXIST_SYSTEM_MSG:'Số điện thoại đã tồn tại trong hệ thống!',
                INVALID_PHONE:'Vui lòng nhập chính xác số điện thoại',
                EMAIL_EXIST_SYSTEM_MSG:'Email đã tồn tại trong hệ thống!',
                INVALID_EMAIL:'Vui lòng nhập chính xác email',
                TEXT_EMAIL_VALIDATOR: "Vui lòng nhập đúng định dạng Email.",
                PASSWORD_NOT_MATCH_MSG:'Mật khẩu không trùng khớp!',
                ERR_PLEASE_TRY_AGAIN:'Xin vui lòng thử lại',
                INT_MIN_VALIDATE:'Nhập số không được âm',
                GREATER_THAN_0_VALIDATE:'Nhập số lớn hơn 0',
                INPUT_MIN_PERCENT_VALIDATE:'Số không được lớn hơn 100',
                INT_MIN_PECENT_VALIDATE:'Vui lòng nhập phí hoa hồng không được âm.',
                INT_MAX_PECENT_VALIDATE:'Vui lòng nhập phí hoa hồng không được lớn hơn 100.',
                WALLET_NOT_ENOUGH_ERR:'Ví tiền người chăm sóc không đủ nhận lịch hẹn.',
                INVALID_CURRENT_PASSWORD_ERR:'Mật khẩu hiện tại không chính xác vui lòng nhập lại.',
                PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR: "Mật khẩu mới không thể giống mật khẩu hiện tại",
                ACCOUNT_IS_NOT_EXISTED_ERR: "Tài khoản không tồn tại",
                ACCOUNT_IS_DEACTIVE_ERR: "Tài khoản không được kích hoạt",
                SESION_LOGIN_EXPIRED: "Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại",
            },
            LIST_TYPE_SERVICE:[
                { value: 1, label: 'Spa & Grooming',style: '', default: true },
                { value: 2, label: 'Cấp cứu khẩn cấp', style: '' },
                { value: 3, label: 'Khám bệnh', style: '' },
                { value: 4, label: 'Pet Hotel', style: '' },
                { value: 5, label: 'HL thú cưng + KDT ngắn hạn', style: '' },
            ],
            LIST_STATUS_APPOINTMENT: [
                {value: 1, label: 'Chờ xác nhận', style: '', default: true, status_display: [1, 2, 6]},
                {value: 2, label: 'Chờ thực hiện', style: '', status_display: [2, 3, 5]},
                {value: 3, label: 'Đang thực hiện', style: '', status_display: [3, 4]},
                {value: 4, label: 'Hoàn tất', style: '', status_display: [4, 7]},
                {value: 5, label: 'Đã hủy', style: '', status_display: [5]},
                {value: 6, label: 'Từ chối', style: '', status_display: [6]},
                {value: 7, label: 'Đã thanh toán', style: '', status_display: [7]},
            ],
            STATUS_APPOINTMENT: {
                WAIT_CONFIRMATION: 1,//Chờ xác nhận
                WAIT_EXECUTION: 2,//Chờ thự hiện
                PROCESSING: 3,//Đang thực hiện
                COMPLETED: 4,//Hoàn Tất
                CANCELLED: 5,//Đã hủy
                REFUSE: 6,//Từ chối
                PAID: 7,//Đã thanh toán
            },
            LIST_STATUS_WITHDRAWALHISTORY:[
                { value: 1, label: 'Chờ duyệt',style: '', default: true },
                { value: 2, label: 'Đã duyệt', style: '' },
                { value: 3, label: 'Từ chối', style: '' },
            ],
            LIST_TYPE_WALLET:[
                { value: 1, label: 'Trả phí hoa hồng dịch vụ',style: '', default: true },
                { value: 2, label: 'Nhận thanh toán dịch vụ', style: '' },
                { value: 3, label: 'Yêu cầu rút tiền', style: '' },
                { value: 4, label: 'Nạp tiền', style: '' },
                { value: 5, label: 'Hoàn phí hoa hồng dịch vụ', style: '' },
                { value: 6, label: 'Hoàn tiền rút tiền', style: '' },
                { value: 7, label: 'Admin thay đổi', style: '' },
            ],
            TYPE_WALLET_TRANSACIONT:{
                PAY_FEE_COMMISSION:1,//Trả phí hoa hồng dịch vụ
                RECEIVE_PAYMENT_SERVICE:1,//Nhận thanh toán dịch vụ
                WITHDRAWAL_REQUEST:3,//Yêu cầu rút tiền
                WALLET_RECHARGE:4,//Nạp tiền
                REFUND_SERVICE_COMMISSIONS:5,//Hoàn phí hoa hồng dịch vụ
                REFUND_WITHDRAWALS:6,//Hoàn tiền rút tiền
                ADMIN_CHANGE:7,//Admin thay đổi
            },
            STATUS_WITHDRAWALH_PENDING: 1,
            STATUS_WITHDRAWALH_APPROVED: 2,
            STATUS_WITHDRAWALH_REFUSE: 3,
            LIST_TYPE_PAYMENT:[
                { value: 1, label: 'Tiền mặt',style: '', default: true },
                { value: 2, label: 'VNPAY', style: '' },
                { value: 3, label: 'MOMO', style: '' },
            ],
            PAYMENT_METHOD:{
                CASH:1,//tiền mặt
                VNPAY:2,//vnpay
                MOMO:3,//momo
            },
            TYPE_SERVICE:{
                SPA_GROOMING:1,
                EMERGENCY:2,//Cấp cứu khẩn cấp
                MEDICAL_EXAMINATION:3,//Khám bệnh
                PET_HOTEL:4,
                EDUCATION_TRAINING:5,//Huấn luyện thú cưng + khóa đào tạo ngắn hạn
            },
            TYPE_IMAGE:{
                USER:'AVATAR',//avatar user
                STORE:'IMAGE_STORE',
                AVATAR_VIDEO:'AVATAR_VIDEO',//avartar video
                VIDEO:'VIDEO',//key lưu url video
                TRAILER:'TRAILER',//key lưu trailler video
                BANNER:'BANNER',//key lưu trailler video
                FEE:'FEE',//key lưu trailler video
                VIDEO_EN:'VIDEO_EN',//key lưu url video
                INTRODUCTION:'INTRODUCTION',//Hình ảnh giới thiệu user
                SERVICE_EDUCATION_TRAINNING:'EDUCATION_TRAINNING',//Hình ảnh đại diện khóa học đào tạo*/
                COVID : 'COVID',//chứng nhận covid
                IDENTITY_CARD: 'IDENTITY_CARD', //CCDD
                LICENSE : 'LICENSE',//bằng lái xe
                PERSONAL : 'PERSONAL',//cá nhân
                BANKS:'BANKS',//hình ngân hàng,
                QR_CODE_IPRO: 'QR_CODE_IPRO',// hình qr mã ipro
                SONG_IMG: 'SONG_IMG', // hình bài hát
                SONG_MUSIC: 'SONG_MUSIC',// nhạc+lời
                ALBUM_IMG: 'ALBUM_IMG', //Ảnh Thumbnail album (1 hình)
                ALBUM_INFOR: 'ALBUM_INFOR', //Ảnh album (Có thể có nhiều hình)
                POLICY: 'POLICY',// Ảnh chính sách
                FILES_LESSON : 'FILES_LESSON',
                COURSES : 'COURSES',
                COMMENT : 'COMMENT',
                ACTIVE_DECKS : 'ACTIVE_DECKS',
                UNACTIVE_DECKS : 'UNACTIVE_DECKS',
            },
            KEY_UPLOAD_FILE_LARGE:{
                VIDEO:'VIDEO',
                TRAILER:'TRAILER',
                VIDEO_EN:'VIDEO_EN',
            },
            API:{
                TRAN_CONVERT_LANG:'/_api/tranlate/convert-lang',
                EXPORT_WITHDRAWAL:'/_api/excel/export-withdrawal',
                EXPORT_APPOINTMENT_STATISTICAL:'/_api/excel/export-appointment-statistical',
                UPDATE_STATUS_APPOINT:'/_api/appointment/update-status',
                UPDATE_STATUS_WITHDRAWAL:'/_api/withdrawal/update-status',
                UPLOAD_FILE:'_api/common/upload-s3',

                REFESH_TOKEN:'/_api/users/refesh_token_admin',
                CHANGE_PASSWORD:'/_api/users/change-password-admin',
                CHECK_TOKEN_ADMIN:'/_api/users/check-token-admin',
                LOGOUT_ADMIN:'/_api/users/logout-admin',
                LIST_CODE_IRPO:'/_api/excel/export-listcode',
                GET_INFO_YOUTUBE:'_api/common/duration_video',
            },
            LANGUAGE:{
                EN:'en',
            },
            LIST_DAY_OF_WEEK:{
                "1":'Thứ 2',
                "2":'Thứ 3',
                "3":'Thứ 4',
                "4":'Thứ 5',
                "5":'Thứ 6',
                "6":'Thứ 7',
                "0":'Chủ Nhật',
            },
            FOLDER_S3:{
                USER:'user',
                IMG_VIDEO : 'img_video',
                SERVICE_EDUCATION_TRAINNING:'education_trainning',
                STORE: 'store',
                BANNER: 'banner',
                FEE: 'fee',
                BANKS: 'banks',
                POLICY: 'POLICY',
                FILES_LESSON : 'files_lesson',
                COURSES : 'courses',
                COMMENT : 'comment',
                DECKS : 'decks'
            },
            SETTING:{
                ATTENDANCE_ADDRESS:'ATTENDANCE_ADDRESS',
                LATTITUDE_ADDRESS:'LATTITUDE_ADDRESS',
                LONGITUDE_ADDRESS:'LONGITUDE_ADDRESS',
                KM_FREE_SHIP:'KM_FREE_SHIP',
                FEE_SHIP_NEXT:'FEE_SHIP_NEXT',
            },
            TEXT_BUTTON:{
                ADD:'Thêm mới',
                EDIT:'Cập nhật',
                BACK:'Quay lại',
            },
            KEY_MENU: {
                LIST_WITHDRAWAL: 'list_withdrawal',
                LIST_STATISTICAL: 'list_statistical',
                EDIT_POLICY: 'edit_policy',
                LIST_WALLET: 'list_wallet',
                LIST_RATE: 'list_rate',
                LIST_MANAGER_USER: 'list_manager_user',
                LIST_MANAGER_SERVICE: 'list_manager_service',
                LIST_SIGNUP_TRAINING: 'list_signup_training',
                LIST_SETTING: 'list_setting',
                LIST_GROUP: 'list_group',
                LIST_STORE: 'list_store',
                LIST_CATEGORY: 'list_category',
                LIST_PRODUCT: 'list_product',
                LIST_APPOINTMENT: 'list_appointment',
                LIST_ORDERS: 'list_orders',
                LIST_NOTIFICATION: 'list_notification',
                LIST_USER: 'list_user',
                LIST_DECKS: 'list_decks',
                LIST_BANNER: 'list_banner',
                LIST_RECORD: 'list_record',
                LIST_STORE_SCAN : 'list_store_scan',
                LIST_IPRO_CODE : 'list-ipro_code',
                LIST_HISTORY_PAYMET:'history_payment',
                LIST_SETTING_FEE:'setting_fee',
                LIST_STATE:'list_state',
                LIST_BANKS: 'list_banks',
                LIST_IPRO_CODE_V2 : 'list-ipro_code_v2',
                LIST_CHAPTER: 'list_chapter',
                LIST_COURSES: 'list_courses',
                LIST_LESSON: 'list_lesson',
                LIST_HISTORY: 'list_history',
                LIST_PACKAGES: 'list_packages',
                LIST_OPTIONS: 'list_options',
            },
            TYPE_ADMIN: {
                SUPPER_ADMIN: 1,
            },
            ROLE_ACCOUNT:{
                SUPPER_ADMIN:'supper_admin',
            },
            ROLE: {
                VIEW: 'view',
                ADD: 'add',
                EDIT: 'edit',
                DELETE: 'delete',
            },
            URL_ERR: {
                NOT_FOUNT: '/404',
                FOBIDDEN: '/403',
                REDIRECT: '/redirect',
                LOGIN: '/auth/login',
            },
            LIST_STATUS_USER:[
                {label: 'Chờ duyệt', value: 1, style: '', default: true,color:'#BC9300'},
                {label: 'Đang hoạt động', value: 2,color:'#00973D'},
                {label: 'Đã khóa', value: 3,color:'#F5564E'},
            ],
            LIST_TYPE_USER_CANCEL:[
                {label: 'Chủ thú cưng', value: 1, style: '', default: true},
                {label: 'Người chăm sóc', value: 2},
            ],
            LIST_TYPE_CHANGE:[
                {label: 'Chủ thú cưng', value: 1, style: '', default: true},
                {label: 'Người chăm sóc', value: 2},
                {label: 'Admin', value: 3},
            ],
            LIST_TYPE_RANK:[
                { value: 'GOLD', name: 'Vàng'},
                { value: 'DIAMOND', name: 'Kim cương'},
                { value: 'PLATINUM', name: 'Bạch kim'}
            ],
            LIST_TYPE_SINGER:[
                { value: 'other', name: 'Khác'},
                { value: 'singer', name: 'Ca sĩ'},
                { value: 'band', name: 'Nhóm nhạc'},
                { value: 'rapper', name: 'Rapper'},


            ],

            LANG_NOTIFICATION:{
                VN:{
                    TITLE_ADMIN_CHANGE_WALLET:"Bạn có giao dịch mới",
                    CONTENT_ADMIN_CHANGE_WALLET:"Bạn được cộng #MONEY# vào tài khoản ví. Nội dung: #CONTENT# ",
                    CONTENT_REDUCTION_ADMIN_CHANGE_WALLET:"Bạn bị trừ #MONEY# vào tài khoản ví. Nội dung: #CONTENT# ",
                    TITLE_ADMIN_CHANGE_STATUS:"Tài khoản",
                    CONTENT_ADMIN_CHANGE_STATUS_PENDING:"Tài khoản của bạn đã bị quản trị viên thay đổi trạng thái chờ duyệt. Vui lòng liên hệ để biết thêm chi tiết.",
                    CONTENT_ADMIN_CHANGE_STATUS_APPROVE:"Tài khoản của bạn đã được phê duyệt. Bạn có thể sử dụng các tính năng của PetCare.",
                    CONTENT_ADMIN_CHANGE_STATUS_LOCK:"Tài khoản của bạn đã bị quản trị viên khóa. Vui lòng liên hệ để biết thêm chi tiết.",
                },
                EN:{
                    TITLE_ADMIN_CHANGE_WALLET:"You have a new transaction from the administrator",
                    CONTENT_ADMIN_CHANGE_WALLET:"You are added #MONEY# to your wallet account. Content: #CONTENT# ",
                    CONTENT_REDUCTION_ADMIN_CHANGE_WALLET:"You have #MONEY# deducted from your wallet account. Content: #CONTENT# ",
                    TITLE_ADMIN_CHANGE_STATUS:"Account",
                    CONTENT_ADMIN_CHANGE_STATUS_PENDING:"Your account has been changed by administrator pending approval. Please contact us for more details.",
                    CONTENT_ADMIN_CHANGE_STATUS_APPROVE:"Your account has been approved. You can use the PetCare features.",
                    CONTENT_ADMIN_CHANGE_STATUS_LOCK:"Your account has been locked by an administrator. Please contact us for more details.",
                }

            },
            TYPE_NOTI:{
                USER:"USER",
                NCS:"NCS",
                CTC:"CTC",
            },
            ACTION_KEY_NOTI:{
                WITHDRAWAL:"WITHDRAWAL",//yêu cầu rút
                APPOINTMENT:"APPOINTMENT",//lịch hẹn
                RECEIVING_APPOINTMENT:"RECEIVING_APPOINTMENT",//push nhận cuốc hẹn cấp cứu khẩn cấp
                CLOSE_SEARCH_APPOINTMENT:"CLOSE_SEARCH_APPOINTMENT",//Key gửi push đến client để đóng màn hình tìm kiếm chuyển sang detail
                WALLET_TRANSACTION:"WALLET_TRANSACTION",//Key gửi push đến màn hình lịch sử ví tiền
                CHANGE_STATUS_ACCOUNT:"CHANGE_STATUS_ACCOUNT",//Key gửi push duyệt và khóa tài khoản
            },
            LIST_OPTION_COMMON:{
                TEXT_TOTAL_ITEM:'Tổng số dòng:',
                PER_PAGE_OPTIONS: [10, 20, 50],
                PAGINATOR_TEMPLATE: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
                PAGINATION_ROWS_DEFAULT:20,
            }

        };
    }
}