import BaseModel from '../core/BaseModel'

export default class DecksModel extends BaseModel {
    constructor() {
        super({ 
                name: "decks",
                tableName: 'decks',
                label: "Danh sách bộ thẻ",
                // display_key: 'name',
                // default_order: { key: 'ordinal', direction: 'asc' } ,
            },
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid",
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            name: {
              name: 'name',
              type: "String",
              label: 'Tên bộ thẻ',
            //   width: 15,
              filter: {type: 'input', match: 'contains'},
              // display: false
            },
            type : {
              label: "Loại bộ thẻ",
              type: "Selection",
              custom: 'SelectionText',
              width : 8,
              // class: 'div-mid',
              options: [
                { value: 1, label: 'Miễn phí',style: 'color:  #000000;'},
                { value: 2, label: 'KOL',style: 'color:  #000000;'},
                { value: 3, label: 'Trả phí',style: 'color:  #000000;'},
              ],
              filter: {
                type: 'select',
                optionLabel: 'label',
                optionKey: 'value',
                match: 'equals'
              },
            },
            total_cards: { 
              name: 'total_cards', 
              type: "Bigint",
              label: 'Số thẻ',
              width: 15,
              // display: false
            },
            ordinal: { 
              name: 'ordinal', 
              type: "Bigint",
              label: 'Thứ tự',
              width: 6,
              // display: false
            },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              width: 8,
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'}
            },
            updated_at : { 
              name: 'updated_at',
              type: "Datetime", 
              width: 8,
              label: 'Ngày cập nhật',
              filter: { type: 'DateRange', match: 'contains'}
            },
        },)
    }

}
