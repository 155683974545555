import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class OptionRepeatModel extends BaseModel {

    constructor() {
        super({ 
                name: "option_repeat",
                tableName: 'option_repeat', 
                label: "Thời gian lặp lại", }, 
            {
            stt: { 
                name: "stt", 
                label: "STT", 
                width : 5,
                class: 'center',
                computed: (data, index) => {
                    data.stt = index + 1;
                }
            },
            id: { 
                name: 'id', 
                type: "bigint", 
                label: 'ID', 
                primaryKey: true, 
                display: false,
            },
            name : { 
                name: 'name', 
                type: "String", 
                label: 'Tên', 
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
            },
            value : { 
                name: 'value', 
                type: "Number", 
                label: 'Thời gian (phút)', 
                sortable: false,
            },
            active: {
                name: "active",
                label: "Hiện/ ẩn",
                type: "Selection",
                custom: 'SelectionText',
                width: 5,
                options: [
                    { value: true, label: 'Hiện',style: 'color:  #000000;'},
                    { value: false, label: 'Ẩn',style: 'color:  #000000;'},
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
                class: "mid",
                // display : false
            },
            created_at : { 
                name: 'created_at',
                type: "Datetime", 
                width: 15,
                label: 'Ngày tạo',
                filter: { type: 'DateRange', match: 'contains'}
            },
            updated_at : { 
                name: 'updated_at',
                type: "Datetime", 
                width: 15,
                label: 'Ngày cập nhật',
                filter: { type: 'DateRange', match: 'contains'}
            },
        },)
    }

}