import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class UserModel extends BaseModel {

    constructor() {
        super({
                name: "user",
                tableName: 'users',
                label: "Quản Lý User/ KOL",
                /* display_key: 'fullname',
                 default_order: { key: 'updated_at', direction: 'asc' }*/
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                
                fullname: { 
                    name: 'fullname',
                    type: "String",
                    label: 'Họ và tên',
                    // width: 12,
                    filter: {type: 'input', match: 'contains'},
                },
                type_user: {
                    name: "type_user",
                    label: "Loại",
                    type: "Selection",
                    custom: 'SelectionText',
                    width : 6,
                    options: [
                      { value: 'kol', label: 'KOL',style: 'color:  #000000;'},
                      { value: 'user', label: 'User',style: 'color:  #000000;'},
                    ],
                    filter: {
                      type: 'select',
                      optionLabel: 'label',
                      optionKey: 'value',
                      match: 'equals'
                    },
                    computed: (data) => {
                      data.type_user = '';
                    },
                },
                
                phone: { 
                    name: 'phone',
                    type: "String",
                    label: 'Số điện thoại',
                    sortable: false,
                    width: 9,
                    filter: {
                        type: 'input',
                        match: 'contains'
                    },
                },
                email : { 
                    name: 'email',
                    type: "String",
                    label: 'Email',
                    width: 16,
                    filter: {type: 'input', match: 'contains'},
                },
                expiration_date : { 
                    name: 'expiration_date', 
                    type: "Datetime", 
                    width: 8,
                    label: 'Ngày hết hạn',
                    filter: { type: 'DateRange', match: 'contains'}
                },
                code : { 
                    name: 'code', 
                    type: "String", 
                    width: 8,
                    label: 'Mã giới thiệu',
                    filter: {type: 'input', match: 'contains'},
                },
                active: {
                    name: "active",
                    label: "Hiển thị",
                    type: "Checkbox",
                    display : false
                },
                deleted: {
                    name: "deleted",
                    label: "Xóa",
                    type: "Checkbox",
                    display: false
                },
                created_at : { 
                    name: 'created_at', 
                    type: "CustomDate", 
                    label: 'Ngày tạo',
                    display: false, 
                    sortable: true,  
                    filter: false,
                },
                updated_at : { 
                    name: 'updated_at', 
                    type: "CustomDate", 
                    label: 'Ngày cập nhật',
                    display: false, 
                    sortable: true,  
                    filter: false,
                },
                role : {
                    name: 'role',
                    type: "String",
                    label: 'role',
                    display: false
                },
                type: {
                    name: "type",
                    label: "Loại user",
                    type: "TEXT",
                    display : false
                },
                is_forever: {
                    name: "is_forever",
                    label: "Vĩnh viễn",
                    type: "Checkbox",
                    display : false
                },
                // rel_data: {
                //     refs: `accounts { account type}`,
                //     display: false
                // },

            },
            )
    }

}