import BaseModel from '../core/BaseModel'

export default class HistoryPayment extends BaseModel {
    constructor() {
        super({ 
                name: "history_payment",
                tableName: 'history_payment',
                label: "Lịch sử thanh toán",
                // display_key: 'name',
                // default_order: { key: 'ordinal', direction: 'asc' } ,
            },
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid",
                label: 'ID', 
                primaryKey: true,
                display: false
            },
            date_add : { 
              name: 'date_add', 
              type: "Date", 
              // width: 8,
              label: 'Ngày thanh toán',
              // filter: { type: 'DateRange', match: 'contains'}
            },
            fullname : {
              name: 'fullname',
              type: "String",
              label: 'Tên user',
              sortable: false,
              computed: (data) => {
                data.fullname = '';
              },
              filter: {type: 'input', match: 'contains'},
            },
            name: {
              name: 'name',
              type: "String",
              label: 'Tên gói',
            //   width: 15,
              filter: {type: 'input', match: 'contains'},
              // display: false
            },
            price: { 
              name: 'price', 
              type: "Number",
              label: 'Giá (VNĐ)',
              // width: 15,
              // display: false
            },
            fullname_kol : {
              name: 'fullname_kol',
              type: "String",
              label: 'Tên KOL',
              sortable: false,
              computed: (data) => {
                data.fullname_kol = '';
              },
              filter: {type: 'input', match: 'contains'},
            },
            kol_price: {
              name: 'kol_price', 
              type: "Number",
              label: 'Hoa hồng (VNĐ)',
              // width: 15,
              // display: false
            },
            // kol_commission: { 
            //   name: 'kol_commission', 
            //   type: "Number",
            //   label: 'Hoa hồng (VNĐ)',
            //   // width: 15,
            //   // display: false
            // },
            created_at : { 
              name: 'created_at', 
              type: "Datetime", 
              // width: 8,
              label: 'Ngày tạo',
              filter: { type: 'DateRange', match: 'contains'}
            },
            updated_at : { 
              name: 'updated_at',
              type: "Datetime", 
              width: 8,
              label: 'Ngày cập nhật',
              filter: { type: 'DateRange', match: 'contains'},
              display: false
            },
            rel_data: {
              refs: `
                user {id fullname}
                kol {id fullname}
              `,
              display: false
            },
        },)
    }

}
