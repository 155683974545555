import BaseModel from "../core/BaseModel";

export default class PolicyModel extends BaseModel {
  constructor() {
    super(
      {
        name: "policy",
        tableName: "policy",
        label: "Chính sách bảo mật",
        display_key: "id",
      },
      {
        stt: { 
          name: "stt", 
          label: "STT", 
          width : 5,
          class: 'center',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Uuid",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        type: {
          name: "type",
          type: "Selection",
          display: false,
          // validations: { required: "Vui lòng chọn loại" },
          label: "Loại",
          // options: [
          //   { value: "policy", label: "Chính sách" },
          // ],
        },
        title: {
          name: "title",
          type: "Text",
          label: "Tiêu đề",
          width: 10,
          // validations: { required: "Vui lòng nhập nội dung" },
          // filter: {type: 'input', match: 'contains'},
        },
        content: {
          name: "content",
          type: "Text",
          label: "Nội dung",
          filter: false,
          // display: false


          // validations: { required: "Vui lòng nhập nội dung" },
          //filter: { type: "input", match: "contains" }
        },
        created_at : { 
          name: 'created_at', 
          type: "Datetime", 
          label: 'Ngày tạo',
          display: false,

      },
      updated_at : { 
          name: 'updated_at', 
          type: "Datetime", 
          width: 12,
          label: 'Ngày cập nhật',
          // filter: { type: 'DateRange', match: 'contains'}
      },
        // policy_lang: { 
        //   refs: 'policy_lang {lang content}',
        //   display: false
        // },
      }
    );
  }
}
