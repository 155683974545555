import crypto from "crypto";
import Cryptojs from 'crypto-js';
export default {
    hashPassword(password){
        var text = (Cryptojs.SHA256(password.toString())).toString() + 'Eng2021'
        return crypto.createHash('sha256').update(text).digest('hex')
    },
    isEmptyObject(value) {
        if (value === undefined || value === null || value === '') {
            return true;
        } else {
            return Object.keys(value).length === 0 && value.constructor === Object;
        }
    },
    numberFormat(number, decimals, dec_point, thousands_sep) {
        var n = number, c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals;
        var d = dec_point == undefined ? "," : dec_point;
        var t = thousands_sep == undefined ? "." : thousands_sep, s = n < 0 ? "-" : "";
        var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;

        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    },
    isEmpty(a_var) {
        if (a_var === undefined || a_var === null || a_var === '')
            return true;
        return false;
    },
    convertJsonObject(obj) {
        if (this.isEmpty(obj)) {
            return null;
        }
        return JSON.parse(JSON.stringify(obj));
    },
    mapObject(objMap, ObjectData) {//Map object remove field not help
        objMap = this.convertJsonObject(objMap);
        for (const [key, value] of Object.entries(objMap)) {
            console.log(value);
            objMap[key] = (!this.isEmptyObject(ObjectData[key])) ? ObjectData[key] : null;
        }
        return objMap;
    }
    ,
    scrollTopElement($container) {
        let element = document.querySelector($container)
        //let element = document.getElementById($container);
        let pos = element.style.position
        let top = element.style.top
        // element.scrollIntoView(true)
        element.style.position = 'relative'
        element.style.top = '-100px'
        element.scrollIntoView({behavior: 'smooth', block: 'start'})
        element.style.top = top
        element.style.position = pos
    },
    makeId(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    convertYoutubeEmbed(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        var id =  (match && match[2].length === 11)
            ? match[2]
            : null;
        var urlConvert = url;
        if (!this.isEmpty(id)){
            urlConvert = 'https://www.youtube.com/embed/'+id;
        }
        return urlConvert;
    },
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    copyTextToClipboard(text) {
        var textArea = document.createElement("textarea");

        //
        // *** This styling is an extra step which is likely not required. ***
        //
        // Why is it here? To ensure:
        // 1. the element is able to have focus and selection.
        // 2. if element was to flash render it has minimal visual impact.
        // 3. less flakyness with selection and copying which **might** occur if
        //    the textarea element is not visible.
        //
        // The likelihood is the element won't even render, not even a
        // flash, so some of these are just precautions. However in
        // Internet Explorer the element is visible whilst the popup
        // box asking the user for permission for the web page to
        // copy to the clipboard.
        //

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copying text command was ' + msg);
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    },
    is_loading(show) {
        var x = document.getElementsByClassName("loader_html");
        if (show == true){
            x[0].style.display = "block";
        }else {
            x[0].style.display = "none";
        }

    },
    is_loading_frontend(show) {
        var x = document.getElementsByClassName("loader_frontend_html");
        if (show == true){
            x[0].style.display = "block";
        }else {
            x[0].style.display = "none";
        }

    },
    isImage(file) {
        return /^image\//.test(file.type);
    },
    convertPhone(numberPhone) {
        var phone = numberPhone
        if (numberPhone[0] === '0') {
            phone = phone.substring(1);
        }
        phone = phone.replace(' ', '')
        phone = '+84' + phone
        return phone
    }, 
    SkipVN(str) {
        if (!str){
            return str;
        }
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "a");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "e");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "o");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "u");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "y");
        str = str.replace(/Đ/g, "d");
        str = str.toLowerCase()
        return str;
    },
 replaceAll(str, find, replace) {
    var find_char = find.replace(/[-[]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    return str.replace(new RegExp(find_char, 'g'), replace);
}

}