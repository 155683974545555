import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class GroupModel extends BaseModel {

    constructor() {
        super({ 
                name: "group",
                tableName: 'group',
                label: "Phân quyền",
                display_key: 'name',
                default_order: { key: 'ordinal', direction: 'asc' } ,
            },
            {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Bigint",
                label: 'ID', 
                primaryKey: true,
                display: false
            },
                name: {
                    name: 'name',
                    type: "String",
                    label: 'Tên nhóm phân quyền',
                    width: 15,
                    filter: {type: 'input', match: 'contains'},
                    // display: false
                },
                group_roles: {
                    refs: `group_roles {
                          id
                          role_id
                        }`,
                    display: false
                }
        },)
    }

}
