import BaseModel from '../core/BaseModel'
//import gql from 'graphql-tag'
export default class StatisticalModels extends BaseModel {

  constructor() {
    super({
            name: "statistical",
            tableName: 'users',
            label: "Thống kê KOL",
        },
        {
            stt: {
                name: "stt",
                label: "STT",
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: {
                name: 'id',
                type: "Uuid",
                label: 'ID',
                primaryKey: true,
                display: false
            },
            
            fullname: { 
                name: 'fullname',
                type: "String",
                label: 'Họ và tên',
                // width: 12,
                filter: {type: 'input', match: 'contains'},
            },
            phone: { 
                name: 'phone',
                type: "String",
                label: 'Số điện thoại',
                sortable: false,
                width: 9,
                filter: {
                    type: 'input',
                    match: 'contains'
                },
            },
            email : { 
                name: 'email',
                type: "String",
                label: 'Email',
                width: 16,
                filter: {type: 'input', match: 'contains'},
            },
            total_user: { 
                name: 'total_user',
                type: "Number",
                label: 'SL giới thiệu',
                // width: 12,
                computed: (data) => {
                    data.total_user = 0
                }
            },
            total_price: { 
                name: 'total_price',
                type: "Number",
                label: 'Hoa hồng (VNĐ)',
                // width: 12,
                computed: (data) => {
                    data.total_user = 0
                }
            },
            // expiration_date : { 
            //     name: 'expiration_date', 
            //     type: "Datetime", 
            //     width: 8,
            //     label: 'Ngày hết hạn',
            //     filter: { type: 'DateRange', match: 'contains'}
            // },
            // code : { 
            //     name: 'code', 
            //     type: "String", 
            //     width: 8,
            //     label: 'Mã giới thiệu',
            //     filter: {type: 'input', match: 'contains'},
            // },
          },
        )
}

}