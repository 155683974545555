//import axios from 'axios'
import {createProvider} from '../vue-apollo'
//import Vue from 'vue';
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
//import Config from "@/config";
import Common from './Common';
import GetConstants from './Constants';

var Constants = GetConstants.getConstans();
import ApiRepository from "@/core/ApiRepository";
import AuthenticationApp from "@/core/AuthenticationApp";
import store from '@/store'
import gql from "graphql-tag";
import moment from "moment-timezone";
import Cryptojs from 'crypto-js'

export default {
    async getImage(type, object_id, key_list) {
        return type + object_id + key_list;
    },
    async getFile(type, object_id, key_list) {
        var table = "file";
        var gqLQueryList = DataServices.getList(table);
        var where = {
            relationship_files: {
                object_id: {_eq: object_id.toString()},
                type: {_eq: type}
            },
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where
            }
        });
        var listData = resData.data[table];
        return {listData: listData, key_list: key_list};
    },
    /*async uploadImages(folder,list_image) {
        var s3client = Config.s3Client(folder)
        var objects = [];
        console.log("list_image:",list_image);
        if (list_image) {
            //this.$store.commit('setLoading', true);
            for (let index = 0; index < list_image.length; index++) {
                const img = list_image[index];
                let file = img.source
                if (file !== '' && img.file !== '') {
                    let fileName = img.name;
                    let random = Math.random().toString().slice(2);
                    fileName = `${random}_${fileName}`
                    await s3client.uploadFile(file, fileName).then((data) => {
                        objects.push(data.location);
                    });
                }
            }
        }
        console.log("objectsobjects:",objects)
        return objects;
    },*/
    async uploadImages(folder, list_image) {
        var objects = [];
        console.log("list_image:", list_image);
        console.log("store.getters.token:", store.getters.token);
        if (list_image) {
            //this.$store.commit('setLoading', true);
            for (let index = 0; index < list_image.length; index++) {
                if (index % 5 == 0) {
                    //nếu 5 tấm sẽ nghỉ 100
                    await Common.sleep(100);
                }
                const img = list_image[index];
                let file = img.source
                if (file !== '' && img.file !== '') {
                    let fileName = img.name;
                    let random = Math.random().toString().slice(2);
                    fileName = `${random}_${fileName}`;

                    var formData = new FormData();
                    formData.append('upload', img);
                    var headerUpload = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            "Authorization": "Bearer " + store.getters.token,
                        }
                    };
                    //console.log("55555555555555");
                    /* var response =  await ApiRepository.post(Constants.API.UPLOAD_FILE,formData,headerUpload);
                     var url_image = response.data.data.url;
                     objects.push(url_image);*/
                    await ApiRepository.post(Constants.API.UPLOAD_FILE, formData, headerUpload).then(function (response) {
                        //var url_image = response.data.data.url;
                        var dataUpload = response.data.data;
                        objects.push(dataUpload);
                    })
                        .catch(function (error) {
                            var message = error.response.data.message;
                            console.error("There was an error!", message);
                            switch (message) {
                                case 'INVALID_TOKEN_ERR':
                                    alert(Constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN)
                                    location.reload();
                                    break;
                                default:
                                    alert(Constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN)
                                //location.reload();
                            }
                        });
                }
            }
        }
        console.log("objectsobjects:", objects)
        return objects;
    },
    async updateDataImage(type, object_id, objectFile) {
        var relationshipFileInsert = {
            'type': type,
            'object_id': (object_id).toString(),
            'file_id': (objectFile.id).toString(),
        };
        await createProvider().defaultClient.mutate({
            //mutation: gql(queryUsers),
            mutation: DataServices.insertData('relationship_file'),
            variables: {
                objects: relationshipFileInsert
            }
        })
            .then((res) => {
                console.log('Thành công save image:', res);
            }).catch(async error => {
                console.log('error', error);
            });
    },
    async deleteDataImage(type, object_id) {
        var table = "relationship_file";
        var gqLQueryList = DataServices.deleteData(table);
        var where = {type: {_eq: type}, object_id: {_eq: object_id.toString()}}
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where
            }
        });
        console.log("assssssssssssssss:", type);
        return resData;
    },
    async deleteDataImageMulti(type, listImageDelete) {
        if (listImageDelete && listImageDelete.length > 0) {
            var listInUrl = [];
            for (var i = 0; i < listImageDelete.length; i++) {
                listInUrl.push(listImageDelete[i].objectURL);
            }
            var table = "file";
            var gqLQueryList = DataServices.getList(table);
            var where = {
                url: {_in: listInUrl}
            }
            var resData = await createProvider().defaultClient.mutate({
                mutation: gqLQueryList,
                variables: {
                    where_key: where
                }
            });
            var listData = resData.data[table];
            //console.log("listDatalistData:",listData);
            var id_image = [];
            if (listData && listData.length > 0) {
                for (var p = 0; p < listData.length; p++) {
                    id_image.push(listData[p].id);
                }
                var table_relationship_image = "relationship_file";
                var gqLQueryListRel = DataServices.deleteData(table_relationship_image);
                var whereRel = {type: {_eq: type}, file_id: {_in: id_image}}
                await createProvider().defaultClient.mutate({
                    mutation: gqLQueryListRel,
                    variables: {
                        where_key: whereRel
                    }
                });
            }
        }
        return true;
    },
    async deleteDataImageMultilple(type, listImageDelete) {
        if (listImageDelete && listImageDelete.length > 0) {
            var listInUrl = [];
            for (var i = 0; i < listImageDelete.length; i++) {
                listInUrl.push(listImageDelete[i].objectURL);
            }
            var table = "image";
            var gqLQueryList = DataServices.getList(table);
            var where = {
                url: {_in: listInUrl}
            }
            var resData = await createProvider().defaultClient.mutate({
                mutation: gqLQueryList,
                variables: {
                    where_key: where
                }
            });
            var listData = resData.data[table];
            //console.log("listDatalistData:",listData);
            var id_image = [];
            if (listData && listData.length > 0) {
                for (var p = 0; p < listData.length; p++) {
                    id_image.push(listData[p].id);
                }
                var table_relationship_image = "relationship_image";
                var gqLQueryListRel = DataServices.deleteData(table_relationship_image);
                var whereRel = {type: {_eq: type}, image_id: {_in: id_image}}
                await createProvider().defaultClient.mutate({
                    mutation: gqLQueryListRel,
                    variables: {
                        where_key: whereRel
                    }
                });
            }
        }
        return true;
    },
    async checkPhoneExist(phone, model) {//check tồn tại sdt
        if (phone !== undefined && phone !== "") {
            // var phone = this.phone;
            // if (this.phone[0] === "0") {
            //   phone = phone.substring(1);
            // }
            // phone = phone.replace(' ', '');
            // phone = "+84" + phone;

            var where = {
                account: {'_eq': phone},
                type: {'_eq': 'PHONE'},
                deleted: {'_eq': false}
            }
            if (model !== undefined) where = Object.assign(where, {id: {'_neq': model.id}})
            var {data} = await createProvider().defaultClient.query({
                query: DataServices.getList('accounts', {"fields": "id, account, type"}),
                variables: {
                    where_key: where
                },
                fetchPolicy: "network-only"
            })
            // console.log(data)
            return data && data[Object.keys(data)].length > 0
        }
    },
    async convertLang(str_convert) {//Dịch tiếng việt sang ngôn ngữ khác
        var data = {
            str_convert: str_convert
        };
        var dataRes = {};
        var response = await ApiRepository.post(Constants.API.TRAN_CONVERT_LANG, data);
        console.log("response", response);
        var str_convert_en = response.data.data.str_convert_en;
        dataRes.str_convert_en = str_convert_en;
        return dataRes;
    },
    async getAccountByUid(uid) {
        var table_cer = 'accounts';
        var where = {uid: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        return listData;
    },
    async getAccountByUidFirebase(uid) {
        var table_cer = 'accounts';
        var where = {id: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async getUsersById(uid) {
        var table_cer = 'users';
        var where = {id: {_eq: uid}};
        var gqLQueryList = await DataServices.getList(table_cer);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_cer];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async getService() {
        var table_service = 'service';
        var where = {active: {_eq: true}, deleted: {_eq: false}};
        var gqLQueryList = await DataServices.getList(table_service);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table_service];
        return listData;
    },
    async getListData(table, where, offset = null, limit = null, orderBy = null) {
        var variables = {
            where_key: where,
        };
        if (offset && limit) {
            variables.limit = limit;
            variables.offset = offset;
        }
        if (orderBy) {
            variables.orderBy = orderBy;
        }
        var gqLQueryList = await DataServices.getList(table);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: variables
        });
        var listData = resData.data[table];
        return listData;
    },
    async getObjectData(table, where, order_by = null) {
        var gqLQueryList = await DataServices.getList(table);
        var variables = {
            where_key: where,
            limit:1
        };
        if (order_by) {
            variables.orderBy = order_by;
        }
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: variables
        });
        var listData = resData.data[table];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    async convertFilter(type_input, key, filters) {
        if (type_input == 'DateRange') {
            if (!Common.isEmpty(filters[key]) && !Common.isEmpty(filters[key][0]) && !Common.isEmpty(filters[key][1])) {
                return {
                    type_input: 'DateRange',
                    value: {
                        from: moment(filters[key][0]).utc().format(),
                        to: moment(filters[key][1]).utc().add(24, 'hours').format()
                    },
                };
            } else {
                return {
                    type_input: 'DateRange',
                    value: null
                };
            }
        } else {
            return {
                type_input: type_input,
                value: filters[key],
            };
        }
    },
    async getSetting(code) {
        var table = 'setting';
        var where = {code: {_eq: code}};
        var gqLQueryList = await DataServices.getList(table);
        var resData = await createProvider().defaultClient.mutate({
            mutation: gqLQueryList,
            variables: {
                where_key: where,
            }
        });
        var listData = resData.data[table];
        if (listData && listData.length > 0) {
            return listData[0];
        }
        return null;
    },
    getPaymentMethod(type_payment) {
        var text = "";
        var list_type_payment = Constants.LIST_TYPE_PAYMENT;
        for (var i = 0; i < list_type_payment.length; i++) {
            if (list_type_payment[i].value == type_payment) {
                text = list_type_payment[i].label;
                break;
            }
        }
        return text;
    },
    async checkPermission(key_controller) {
        //var uid_user = store.getters.user;
        var uid_user = await AuthenticationApp.getUid();
        var users = await this.getUsersById(uid_user);
        // console.log("users",users)
        var list_role = [];
        //users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN
        if (users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN) {
            // if (account.type_admin == Constants.TYPE_ADMIN.SUPPER_ADMIN) {
            for (const [key, value] of Object.entries(Constants.ROLE)) {
                console.log(key);
                list_role.push(value);//full quyền
            }
            return list_role;
        }
        //type: {_eq: "${role}"},
        var query_controller = `query MyQuery {
          group_user(where: {uid: {_eq: "${uid_user}"}}) {
            group {
              group_roles(where: {role: { controller: {active: {_eq: true}, deleted: {_eq: false}, _key: {_eq: "${key_controller}"}}}}, order_by: {role: {controller: {ordinal: desc}}}) {
                role {
                  type
                  controller {
                    id
                    name
                    ordinal
                    parent_id
                    parent_tab
                    icon
                    _key
                    url
                    tab_service
                  }
                }
              }
            }
          }
        }
      `
        var resData = await createProvider().defaultClient.mutate({
            mutation: gql(query_controller)
        });
        console.log("resData.data.group_user",resData.data.group_user);
        var group_user = (!Common.isEmptyObject(resData.data.group_user)) ? resData.data.group_user : [];
        // var is_permission = false;
        console.log("group_user",group_user);
        if (group_user && group_user.length > 0) {
            var group_user_obj = group_user[0];
            console.log("group_user_obj", group_user_obj)
            if (group_user_obj) {
                if (group_user_obj.group) {
                    if (group_user_obj.group.group_roles) {
                        var controller_list = group_user_obj.group.group_roles;
                        for (var m = 0; m < controller_list.length; m++) {
                            if (controller_list[m].role) {
                                if (controller_list[m].role.type) {
                                    list_role.push(controller_list[m].role.type);
                                }
                            }
                        }
                    }
                }
            }
        }
        return list_role;
    },
    async getListControllerGroupUser(uid_user, offset = null, limit = null) {
        var query_controller = `query MyQuery ($offset: Int, $limit: Int) {
          group_user(where: {uid: {_eq: "${uid_user}"}}) {
            group {
              group_roles(where: {role: {type: {_eq: "view"}, controller: {active: {_eq: true}, deleted: {_eq: false}}}}, order_by: {role: {controller: {ordinal: desc}}}, offset: $offset, limit: $limit) {
                role {
                  type
                  controller {
                    id
                    name
                    ordinal
                    parent_id
                    parent_tab
                    icon
                    _key
                    url
                    tab_service
                    rel_arr_controller_child(order_by: {ordinal: desc}, where: {active: {_eq: true}, deleted: {_eq: false}}) {
                      id
                      name
                      icon
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `
        var resData = await createProvider().defaultClient.mutate({
            mutation: gql(query_controller),
            variables: {
                offset: offset,
                limit: limit,
            }
        });
        var group_user = (!Common.isEmptyObject(resData.data.group_user)) ? resData.data.group_user : [];

        return group_user;
    },
    async geturlRedirectLogin() {
        //var account = store.getters.accountUser;
        //var uid_user = store.getters.user;
        var uid_user = await AuthenticationApp.getUid();
        /*var account = await this.getAccountByUidFirebase(uid_admin);
        console.log("accountaccountaccount:",account);
        var uid_user = (account) ? account.uid:null;*/
        var users = await this.getUsersById(uid_user);
        console.log("accountaccountaccount:", users);
        var controller_list = [];
        var obj_cobntroller;
        if (users.role == Constants.ROLE_ACCOUNT.SUPPER_ADMIN) {
            var where_controller = {active: {_eq: true}, deleted: {_eq: false}, parent_tab: {_eq: true}};
            var order_by_controller = {ordinal: 'desc'};
            controller_list = await this.getListData('controller', where_controller, 0, 1, order_by_controller);
            obj_cobntroller = (controller_list.length > 0) ? controller_list[0] : null;
        } else {
            var group_user = await this.getListControllerGroupUser(uid_user, 0, 1);
            if (group_user && group_user.length > 0) {
                var group_user_obj = group_user[0];
                console.log("group_user_obj", group_user_obj)
                if (group_user_obj) {
                    if (group_user_obj.group) {
                        if (group_user_obj.group.group_roles) {
                            controller_list = group_user_obj.group.group_roles;
                            if (controller_list.length > 0) {
                                if (controller_list[0].role) {
                                    if (controller_list[0].role.controller) {
                                        obj_cobntroller = controller_list[0].role.controller;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        var arr_menu = [];
        var menu_education = [];
        /*var listService = await this.getService();
        if (listService && listService.length > 0){
            for (var i=0;i<listService.length;i++){
                if(listService[i].type == 5){
                    menu_education.push({
                        label: listService[i].name, icon: 'record-icon', to: '/admin/list-register/list/'+listService[i].id, id: 'list_register_'+listService[i].id
                    })
                }
                arr_menu.push(
                    {
                        label: listService[i].name, icon: 'record-icon', to: '/admin/list-service/list/'+listService[i].id, id: 'list_service_'+listService[i].id
                    }
                );
            }
        }*/
        console.log("obj_cobntrollerobj_cobntrollerobj_cobntroller:", obj_cobntroller)
        var url = null;
        if (obj_cobntroller) {
            if (!Common.isEmpty(obj_cobntroller.url)) {
                url = obj_cobntroller.url;
            } else {
                //nếu tồn tại menu child
                switch (obj_cobntroller.tab_service) {
                    case 0:
                        if (obj_cobntroller.rel_arr_controller_child && obj_cobntroller.rel_arr_controller_child.length > 0) {
                            var list_menu_child = obj_cobntroller.rel_arr_controller_child;
                            if (list_menu_child.length > 0) {
                                url = list_menu_child[0].url;
                            }
                        }
                        break;
                    case 1:
                        if (arr_menu.length > 0) {
                            url = arr_menu[0].to;
                        }
                        break;
                    case 2:
                        if (menu_education.length > 0) {
                            url = menu_education[0].to;
                        }
                        break;
                    default:
                    // code block
                }
            }

        }
        return url;

    },
    async uploadMultilpart(file, key_status, key_status_progress_bar) {
        //var calculateMD5 = await this.calculateMD5(file);
        var chunkSize = 1024 * 1024 * 6;//1 phận đoạn 6MB
        var chunks_lenght = Math.ceil(file.size / chunkSize);
        var dataRequest = {
            name_file: file.name,
            parts: chunks_lenght,
        };
        var dataState = {
            status: 1,//1:pending,2:error,3:thành công
        }
        const headers = {
            "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
        };
        ApiRepository.post('/_api/upload-chunk/init_multipart_upload', dataRequest, {headers}).then(async (response) => {
            var dataSuccess = response.data;
            var dataObject = dataSuccess.data;
            var key_s3 = dataObject.key;
            var UploadId = dataObject.UploadId;
            var list_url = (dataObject.list_url) ? dataObject.list_url : [];
            console.log("list_urllist_url", list_url)
            delete ApiRepository.defaults.headers.put['Content-Type']
            if (list_url.length > 0) {
                var listPart = [];
                var count_done = 0;
                var count_error = 0;
                for (var i = 0; i < list_url.length; i++) {
                    if (i % 5 == 0) {
                        await Common.sleep(3000);//deplay 3 giây
                    }
                    const start = i * chunkSize;
                    const end = (i + 1) * chunkSize;
                    const blob = (i < list_url.length) ? file.slice(start, end) : file.slice(start);
                    this.sendChunkS3(list_url[i], blob, i).then(async (response) => {
                        if (response) {
                            count_done++;
                            if (count_done % 5 == 0) {
                                //setprocessbar
                                localStorage.setItem(key_status_progress_bar, ((count_done / list_url.length) * 100).toString());
                            }
                            console.log("responseS3:", response);
                            listPart.push(response);
                            if (count_done == list_url.length) {
                                var dataRequestComplete = {
                                    key_s3: key_s3,
                                    UploadId: UploadId,
                                    parts: listPart,
                                };
                                const headers = {
                                    "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
                                };
                                ApiRepository.post('/_api/upload-chunk/complete_multi_upload', dataRequestComplete, {headers}).then(async (responseComplete) => {
                                    console.log("responseComplete", responseComplete)
                                    var dataComplete = responseComplete.data;
                                    var dataObjectComplete = dataComplete.data;
                                    /*console.log("dataObjectComplete:",(Cryptojs.MD5(file)).toString());
                                    console.log("dataObjectComplete:",(Cryptojs.MD5(dataObjectComplete.url)).toString());*/
                                    //this.downloadAndCreateHash( dataObjectComplete.url);
                                    dataState.status = 3;//Thành công
                                    dataState.data = dataObjectComplete;//Thành công
                                    localStorage.setItem(key_status, JSON.stringify(dataState));
                                })
                            }
                        } else {
                            count_error++;
                            //Nếu lỗi lần đầu thì update còn lần tiếp theo thì ko update
                            if (count_error == 1) {
                                dataState.status = 2;
                                localStorage.setItem(key_status, JSON.stringify(dataState));
                                //alert('Upload file thất bại! Vui lòng thử lại');
                                //Gọi APi hủy UploadId
                                this.abort_multipart_upload(key_s3, UploadId)
                            }

                        }
                    })
                        .catch((error) => {
                            console.log("error::", error)
                            count_error++;
                            //Nếu lỗi lần đầu thì update còn lần tiếp theo thì ko update
                            if (count_error == 1) {
                                //alert('Upload file thất bại! Vui lòng thử lại');
                                dataState.status = 2;
                                localStorage.setItem(key_status, JSON.stringify(dataState));
                                //Gọi APi hủy UploadId
                                this.abort_multipart_upload(key_s3, UploadId)
                            }
                        })
                }
            }
        })
            .catch((error) => {
                console.log(error)
                //alert('Upload file thất bại! Vui lòng thử lại');
                dataState.status = 2;
                localStorage.setItem(key_status, JSON.stringify(dataState));
            })
    },
    async sendChunkS3(url, blob, i) {
        try {
            var response = await ApiRepository.put(url, blob);
            console.log("(Cryptojs.SHA256(this.password.toString())).toString():", (Cryptojs.MD5(blob)).toString())
            console.log("response.headers.etag:", response.headers.etag)
            var hashChunk = await this.calculateMD5(blob);
            console.log("hashChunk:"+i+":"+hashChunk);
            console.log("response.headers.etag.trim():"+i+":"+response.headers.etag.trim());
            var Etag = response.headers.etag;
            //vi etag chứa thêm nhũng ký tự "" || nên dùng indexof
            if ((Etag.indexOf(hashChunk) > -1)){
                return {
                    ETag: response.headers.etag,
                    PartNumber: i + 1
                };
            }else {
                console.log("SAI:"+i)
                return false;//ko toàn vẹn dữ liệu
            }

        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async abort_multipart_upload(key_s3, UploadId) { //HỦy một state upload
        try {
            var dataRequest = {
                key_s3: key_s3,
                UploadId: UploadId,
            };
            const headers = {
                "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
            };
            await ApiRepository.post('/_api/upload-chunk/abort_multipart_upload', dataRequest, {headers});
        } catch (err) {
            console.log(err);
        }
    },
    fileHash(file, callback) {
        //Instantiate a reader
        var reader = new FileReader();

        //What to do when we gets data?
        reader.onload = function (e) {
            var hash = (Cryptojs.MD5(e.target.result)).toString();
            callback(hash);
        }
        reader.readAsBinaryString(file);
    }
    ,
    async downloadAndCreateHash(url) {
        fetch(url)
            .then(function (response) {
                return response.blob()
            })
            .then(blob => {
                return this.calculateMD5(blob)
            })
            .then(hash => {
                console.log(`Hash of the file is: ${hash}`)
            })
            .catch(err => {
                console.error(err)
            });

    },
    calculateMD5(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onloadend = function () {
                var wordArray = Cryptojs.lib.WordArray.create(reader.result),
                    hash = Cryptojs.MD5(wordArray).toString();
                resolve(hash)
            };
        })
    },
    async createAlbumActive(album_id, number) { //HỦy một state upload
        try {
            var dataRequest = {
                album_id: album_id,
                number: number,
            };
            const headers = {
                "Authorization": "Bearer " + (await AuthenticationApp.getToken()),
            };
            await ApiRepository.post('/_api/common/create_album_active', dataRequest, {headers}).then(async(response)=>{
                return response;
            });
        } catch (err) {
            console.log(err);
        }
    },

}
