import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Front')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/pages/NotFound/index')
  },
  {
    path: '/403',
    name: '403',
    component: () => import('../views/pages/Forbidden/index')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../views/pages/redirect')
  },
  /*{
    path: '/webview/notification/:id',
    name: 'redirect',
    component: () => import('../views/pages/Webview/Notification')
  },*/
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/auth/AuthLayout'),
    children: [
      {
        path: '/',
        redirect: "/auth/login",
        component: () => import('../views/auth/Login')
      },
      {
        path: '/auth/login',
        name: 'login',
        component: () => import('../views/auth/Login')
      },
      {
        path: '/auth/change-password',
        name: 'changePassword',
        component: () => import('../views/auth/changePassword')
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/AdminLayout'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list-users',
        name: 'user',
        component: () => import('../views/admin/user'),
        default: true,
        meta: {
          model: 'users',
          requiresAuth: true,
        }
      },
      {
        path: 'list-users/add',
        name: 'addUser',
        component: () => import('../views/admin/user/function'),
        meta: {
          model: 'users',
          requiresAuth: true,
        }
      },
      {
        path: 'list-users/edit/:id',
        name: 'editUser',
        component: () => import('../views/admin/user/function'),
        meta: {
          model: 'users',
          requiresAuth: true,
        }
      },
      {
        path: 'list-users/view/:id',
        name: 'viewUser',
        component: () => import('../views/admin/user/view'),
        meta: {
          model: 'users',
          requiresAuth: true,
        }
      },
      
      // {
      //   path: 'list-view_admin',
      //   name: 'admin',
      //   component: () => import('../views/admin/admin'),
      //   default: true,
      //   meta: {
      //     model: 'view_admin',
      //     requiresAuth: true,
      //   }
      // },
      // {
      //   path: 'list-view_admin/add',
      //   name: 'addAdmin',
      //   component: () => import('../views/admin/admin/function'),
      //   meta: {
      //     model: 'view_admin',
      //     requiresAuth: true,
      //   }
      // },
      // {
      //   path: 'list-view_admin/edit/:id',
      //   name: 'editAdmin',
      //   component: () => import('../views/admin/admin/function'),
      //   meta: {
      //     model: 'view_admin',
      //     requiresAuth: true,
      //   }
      // },
      {
        path: 'list-policy',
        name: 'policy',
        component: () => import('../views/admin/policy'),
        meta: {
          model: 'policy',
          requiresAuth: true
        }
      },
      {
        path: 'list-policy/edit/:id',
        name: 'editPolicy',
        component: () => import('../views/admin/policy/function'),
        meta: {
          model: 'policy',
          requiresAuth: true
        }
      },
      {
        path: 'list-notification',
        name: 'notification',
        component: () => import('../views/admin/notification'),
        default: true,
        meta: {
          model: 'notification',
          requiresAuth: true,
        }
      },
      {
        path: 'list-notification/add',
        name: 'addNotification',
        component: () => import('../views/admin/notification/function'),
        meta: {
          model: 'notification',
          requiresAuth: true
        }
      },
      {
        path: 'list-notification/edit/:id',
        name: 'editNotification',
        component: () => import('../views/admin/notification/function'),
        meta: {
          model: 'notification',
          requiresAuth: true
        }
      },
      {
        path: 'list-group',
        name: 'group',
        component: () => import('../views/admin/permission'),
        default: true,
        meta: {
          model: 'group',
          requiresAuth: true,
        }
      },
      {
        path: 'list-group/add',
        name: 'addGroup',
        component: () => import('../views/admin/permission/function'),
        meta: {
          model: 'group',
          requiresAuth: true,
        }
      },
      {
        path: 'list-group/edit/:id',
        name: 'editGroup',
        component: () => import('../views/admin/permission/function'),
        meta: {
          model: 'group',
          requiresAuth: true,
        }
      },
      {
        path: 'list-decks',
        name: 'decks',
        component: () => import('../views/admin/decks'),
        default: true,
        meta: {
          model: 'decks',
          requiresAuth: true,
        }
      },
      {
        path: 'list-decks/add',
        name: 'addDecks',
        component: () => import('../views/admin/decks/function'),
        meta: {
          model: 'decks',
          requiresAuth: true,
        }
      },
      {
        path: 'list-decks/edit/:id',
        name: 'editDecks',
        component: () => import('../views/admin/decks/function'),
        meta: {
          model: 'decks',
          requiresAuth: true,
        }
      },
      {
        path: 'list-history',
        name: 'history',
        component: () => import('../views/admin/history_payment'),
        default: true,
        meta: {
          model: 'history_payment',
          requiresAuth: true,
        }
      },
      {
        path: 'list-history/add',
        name: 'addHistory',
        component: () => import('../views/admin/history_payment/function'),
        meta: {
          model: 'history_payment',
          requiresAuth: true,
        }
      },
      {
        path: 'list-statistical',
        name: 'statistical',
        component: () => import('../views/admin/statistical'),
        default: true,
        meta: {
          model: 'statistical',
          requiresAuth: true,
        }
      },
      {
        path: 'list-packages',
        name: 'packages',
        component: () => import('../views/admin/packages'),
        default: true,
        meta: {
          model: 'packages',
          requiresAuth: true,
        }
      },
      {
        path: 'list-packages/add',
        name: 'addPackages',
        component: () => import('../views/admin/packages/function'),
        meta: {
          model: 'packages',
          requiresAuth: true,
        }
      },
      {
        path: 'list-packages/edit/:id',
        name: 'editPackages',
        component: () => import('../views/admin/packages/function'),
        meta: {
          model: 'packages',
          requiresAuth: true,
        }
      },
      {
        path: 'list-options',
        name: 'option_repeat',
        component: () => import('../views/admin/option_repeat'),
        default: true,
        meta: {
          model: 'option_repeat',
          requiresAuth: true,
        }
      },
      {
        path: 'list-options/add',
        name: 'addOption',
        component: () => import('../views/admin/option_repeat/function'),
        meta: {
          model: 'option_repeat',
          requiresAuth: true,
        }
      },
      {
        path: 'list-options/edit/:id',
        name: 'editOption',
        component: () => import('../views/admin/option_repeat/function'),
        meta: {
          model: 'option_repeat',
          requiresAuth: true,
        }
      },
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  //base: process.env.BASE_URL,
})


export default router